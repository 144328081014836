/* ./src/styles/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Albert Sans";
  src: url("./fonts/Albert\ Sans/AlbertSans-VariableFont_wght.woff2")
      format("woff2"),
    url("./fonts/Albert\ Sans/AlbertSans-VariableFont_wght.woff") format("woff");
  font-weight: 100 700;
  font-style: normal;
}
/*
  This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: http://www.fonts.com
*/

.bright-gradient {
  background: radial-gradient(
    80.41% 193.96% at 100.34% 103.12%,
    #ff41a6 0%,
    #b860bf 33.6%,
    #0070e0 100%
  );
}

.slick-prev,
.slick-next {
  @apply absolute right-0 z-50 flex items-center w-12 h-12 p-0 bg-white border rounded shadow-lg cursor-pointer inset-y-2/4 justify-items-center;
  font-size: 0;
  line-height: 0;
  transform: translate(0, -50%);
  color: transparent;
  outline: none;
}

.slick-next {
  @apply -right-4;
}

.slick-prev {
  @apply -left-4;
}

.slick-prev:before,
.slick-next:before {
}

.slick-prev:before {
  content: url(./images/prev.svg);
  @apply w-full h-full;
}

.slick-next:before {
  content: url(./images/next.svg);
  @apply w-full h-full;
}

.slick-slider {
  @apply box-border relative block;
}

.slick-list {
  @apply relative block p-0 m-0 overflow-hidden;
}

.slick-track {
  @apply relative top-0 left-0 flex items-stretch ml-auto mr-auto;
}

.slick-slide {
  @apply hidden min-h-0;
}

.slick-slide > div {
  @apply h-full;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-dots {
  @apply absolute flex w-full p-0 m-0 space-x-4 text-center list-none -bottom-7;
}

.slick-dots li {
  @apply relative inline-block p-0 m-0 cursor-pointer;
}

.slick-dots li button {
  @apply block w-3 h-3 p-0 leading-none bg-transparent border-2 border-blue-300 rounded-full opacity-50 cursor-pointer;
  font-size: 0;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  @apply bg-blue-300 border-2 border-blue-300 opacity-100;
  outline: none;
}

.toggle-checkbox:checked {
  @apply: right-0 border-blue-300;
  right: 0;
  border-color: #0057ad;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-blue-400;
  background-color: #0057ad;
}
